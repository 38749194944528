import React, { useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

const _ = require("lodash");

const countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

// import PropTypes from "prop-types";

// import Headline from "../Article/Headline";
// import Bodytext from "../Article/Bodytext";

const TadigList = props => {
  // useEffect(() => {
  //   var mytable = document.getElementsByTagName("table")
  //   console.log(mytable)
  //   var myrows = mytable[0].rows
  //   console.log(myrows, myrows.length)

  //   for(let i=0;i < myrows.length;i++){
  //     var oldinner;
  //     oldinner = myrows[i].cells[0].innerHTML;
  //     myrows[i].cells[0].innerHTML = `<a>${oldinner}</a>`;
  //   }
  // },[])

  const query = useStaticQuery(graphql`
    query AllTadig {
      allTs25ExtractCsv(sort: { fields: TADIG_code }, filter: { TADIG_code: { ne: "" } }) {
        nodes {
          Country_Initials
          TADIG_code
          Network
        }
      }
      allE212Csv(sort: { fields: Network_code }, filter: { Network_code: { ne: "" } }) {
        nodes {
          Country_code__ISO_CC3_
          Network_name
          Network_code
        }
      }
    }
  `);

  let tadigsTS25 = Array.from(new Set(query.allTs25ExtractCsv.nodes.map(JSON.stringify))).map(
    JSON.parse
  );

  tadigsTS25 = tadigsTS25.map(e => {
    e.Country = countries.getName(e.Country_Initials, "en") || "N/A";
    e.ISO = e.Country_Initials;
    delete e.Country_Initials;
    e.TADIG = e.TADIG_code;
    delete e.TADIG_code;
    e.Operator = e.Network;
    delete e.Network;
    return e;
  });
  // console.log(tadigsTS25.length)

  let tadigsE212 = Array.from(new Set(query.allE212Csv.nodes.map(JSON.stringify))).map(JSON.parse);

  tadigsE212 = tadigsE212.map(e => {
    e.Country = countries.getName(e.Country_code__ISO_CC3_, "en") || "N/A";
    e.ISO = e.Country_code__ISO_CC3_;
    delete e.Country_code__ISO_CC3_;
    e.TADIG = e.Network_code;
    delete e.Network_code;
    e.Operator = e.Network_name;
    delete e.Network_name;
    return e;
  });
  // console.log(tadigsE212.length)

  let allTadig = [...tadigsTS25, ...tadigsE212];
  // console.log(allTadig.length)

  allTadig = _.uniqBy(allTadig, "TADIG");
  allTadig = _.orderBy(allTadig, ["Country", "TADIG"], ["asc", "asc"]);
  // console.log(allTadig)

  // Manual corrections
  allTadig = allTadig.map(t => {
    if(t.TADIG === 'UGATL')
      t.Operator = 'Uganda Telecom Ltd'
    
    return t
  })

  return (
    <React.Fragment>
      <table>
        <thead>
          <tr>
            <th>TADIG Code</th>
            <th>Operator Name</th>
            <th>Country</th>
          </tr>
        </thead>
        <tbody>
          {allTadig.map(row => {
            return (
              <tr key={row.TADIG}>
                <td>
                  <Link to={`/tadig/${row.TADIG}`}>{row.TADIG}</Link>
                </td>
                <td>{row.Operator}</td>
                <td>{row.Country}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default TadigList;
