import React from "react";
// import PropTypes from "prop-types";
// import { graphql } from "gatsby";
import Seo from "../components/Seo";
import Article from "../components/Article";
import TadigList from "../components/TadigList";
import Headline from "../components/Article/Headline";
// import Bodytext from "../components/Article/Bodytext";
import { ThemeContext } from "../layouts";
import { Link } from "gatsby"

const PageTadig = props => {
  // const {
  //   data: {
  //     page,
  //     site: {
  //       siteMetadata: { facebook }
  //     }
  //   }
  // } = props;

  const page = {
    frontmatter: {
      title: "List of TADIG Codes",
      description:
        "List of TADIG (Transferred Account Data Interchange Group) codes, the unique identifier for network operators in GSM mobile networks."
    },
    fields: {
      slug: "/list-of-tadig-codes"
    }
  };

  return (
    <React.Fragment>
      <ThemeContext.Consumer>
        {theme => (
          <Article theme={theme}>
            <header>
              <Headline title="List of TADIG Codes" theme={theme} />
            </header>

            <div className="bodytext">
              <p>
                Below is a list of TADIG (Transferred Account Data Interchange Group) codes, the
                unique identifier for network operators in GSM mobile networks.
              </p>

              <p>Try out our free MSISDN/IMSI lookup service <Link to="/#lookup">here</Link>.</p>

              <TadigList />
            </div>

            <style jsx>{`
              .bodytext {
                animation-name: bodytextEntry;
                animation-duration: ${theme.time.duration.long};

                :global(h2),
                :global(h3) {
                  margin: 1.5em 0 1em;
                }

                :global(h2) {
                  line-height: ${theme.font.lineHeight.s};
                  font-size: ${theme.font.size.l};
                }

                :global(h3) {
                  font-size: ${theme.font.size.m};
                  line-height: ${theme.font.lineHeight.m};
                }

                :global(p) {
                  font-size: ${theme.font.size.s};
                  line-height: ${theme.font.lineHeight.xxl};
                  margin: 0.75em 0;
                }
                :global(ul) {
                  list-style: circle;
                  margin: 0.75em 0;
                  padding: 0.75em 0;
                }
                :global(li) {
                  margin: 0.7em 0;
                  line-height: 1.5;
                }
                :global(a) {
                  font-weight: ${theme.font.weight.bold};
                  color: ${theme.color.brand.primary};
                  text-decoration: underline;
                }
                :global(a.gatsby-resp-image-link) {
                  border: 0;
                  display: block;
                  margin: 2.5em 0;
                  border-radius: ${theme.size.radius.default};
                  overflow: hidden;
                  border: 1px solid ${theme.line.color};
                }
                :global(code.language-text) {
                  background: ${theme.color.neutral.gray.c};
                  text-shadow: none;
                  color: inherit;
                  padding: 0.1em 0.3em 0.2em;
                  border-radius: 0.1em;
                }
              }

              @keyframes bodytextEntry {
                from {
                  opacity: 0;
                }
                to {
                  opacity: 1;
                }
              }
            `}</style>
          </Article>
        )}
      </ThemeContext.Consumer>

      <Seo data={page} facebook={{}} />
    </React.Fragment>
  );
};

// PageTadig.propTypes = {
//   data: PropTypes.object.isRequired
// };

export default PageTadig;

//eslint-disable-next-line no-undef
// export const pageQuery = graphql`
//   query PageByPath($slug: String!) {
//     page: markdownRemark(fields: { slug: { eq: $slug } }) {
//       id
//       html
//       frontmatter {
//         title
//         cover {
//           childImageSharp {
//             resize(width: 300) {
//               src
//             }
//           }
//         }
//       }
//     }
//     site {
//       siteMetadata {
//         facebook {
//           appId
//         }
//       }
//     }
//   }
// `;
